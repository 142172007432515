import type * as React from 'react';
import { Link } from '@dx-ui/osc-link';
import cx from 'classnames';

type HeadlineProps = {
  supportingText?: string | React.ReactNode;
  joinLink?: { url: string; text: string; linkClassName?: string } | null;
  isStacked?: boolean;
  headlineClassName?: string;
  className?: string;
} & React.HTMLAttributes<HTMLDivElement>;

export const Headline = ({
  className,
  supportingText,
  joinLink,
  isStacked = true,
  children,
  headlineClassName,
  ...rest
}: HeadlineProps) => {
  return (
    <div
      className={cx(
        {
          'flex flex-col md:flex-row md:space-x-4 md:rtl:space-x-reverse': !isStacked,
        },
        className
      )}
      {...rest}
    >
      <h1
        className={cx(
          'font-headline text-3xl font-semibold',
          {
            'md:w-3/12': !isStacked,
          },
          headlineClassName
        )}
      >
        {children}
      </h1>
      {supportingText ? (
        <div
          className={cx({
            'my-4 md:my-0': !isStacked,
            'md:w-7/12': !isStacked && joinLink,
            'md:w-9/12': !isStacked && !joinLink,
          })}
        >
          {supportingText}
        </div>
      ) : null}
      {joinLink ? (
        <span className="flex w-full flex-col md:w-2/12">
          <Link
            url={joinLink?.url}
            className={cx(
              'btn btn-2xl w-full text-wrap px-2 text-center text-sm no-underline lg:text-xl',
              joinLink?.linkClassName
            )}
          >
            {joinLink?.text}
          </Link>
        </span>
      ) : null}
    </div>
  );
};
