
    (window.__NEXT_P = window.__NEXT_P || []).push([
      "/go-hilton",
      function () {
        return require("private-next-pages/go-hilton/index.tsx");
      }
    ]);
    if(module.hot) {
      module.hot.dispose(function () {
        window.__NEXT_P.push(["/go-hilton"])
      });
    }
  