/* eslint-disable import/no-cycle */
import type * as React from 'react';
import { useAuth } from '@dx-ui/framework-auth-provider';
import { LoginIFrame } from '@dx-ui/osc-login';
import { useTranslation } from 'next-i18next';
import { env } from '@dx-ui/framework-env';

import { useLanguage } from '../../hooks';
import { useMetrics } from '../../hooks/useMetrics';
import { getUrlHost } from '../../utils/helpers/urlBuilder';

import type { AuthResponse } from '@dx-ui/framework-auth-provider';
import type { LoginResponse } from '@dx-ui/osc-login';

type LoginComponentOptions = {
  showJoinInfo?: boolean;
  showResponsiveHeader?: boolean;
};

type LoginComponentFns = {
  toggleFlyout?: () => void;
  onLogin?: (data: AuthResponse) => void;
  handleMessage?: () => void;
  setError?: () => void;
};

type InputProps = LoginComponentOptions &
  LoginComponentFns & {
    hasRecaptcha?: boolean | null;
    language: string;
    source?: string;
    frameSrc?: string;
  };

const LoginFrame: React.FC<React.PropsWithChildren<InputProps>> = (props) => {
  const { onLogin, source = 'Honors', frameSrc = '' } = props;
  const language = useLanguage();
  const authState = useAuth();
  const { metrics } = useMetrics();

  const [t] = useTranslation('login');

  const handleLogin = async ({ data, error }: LoginResponse) => {
    if (error) {
      metrics.trackLoginError({ type: error?.status, source: `${source}LoginError` });
    } else if (data) {
      await authState.login({ data });
      onLogin?.(data);
    }
    return false;
  };

  return (
    <LoginIFrame
      title={t('loginContent.frameTitle')}
      frameSrc={frameSrc}
      options={{
        showCreatePassword: true,
        forgetInfoLink: {
          url: `${getUrlHost()}/${language}/hilton-honors/login/forgot-information/`,
        },
        secureInfoLink: {
          url: `${env('OHW_BASE_URL')}${language}/p/global-privacy-statement/`,
        },
      }}
      data-e2e="loginIframe"
      onLoginAttempt={handleLogin}
      onClose={() => null}
    />
  );
};

export default LoginFrame;
